<template>
  <div>

    <div class="home-banner">
    </div>
    
    <CJumbotron class="home-shoutout">
      <img src="https://cdn.s777.club/web/cat.png"/>
      <!--<img src="https://cdn.s777.club/web/cat-halloween.png"/>-->
      <!--<img src="https://cdn.s777.club/web/cat-christmas.png"/>-->
      <h1>Super Awesome Fish Table<br/>& Slot Games</h1>
      <!--<p class="lead">Claim your welcome bonus</p>
      <CButton color="primary" href="https://coreui.io/" target="_blank">More Info</CButton>-->
      <CButton shape="pill" size="lg" class="home-signup" @click="openSignupModal" v-if='!isLoggedIn'>Sign Up Now</CButton>
      <CButton shape="pill" size="lg" class="home-signup" @click="openDepositModal" v-if='isLoggedIn'>Deposit & Play</CButton>
      <small class="home-tc">* Terms & conditions apply.</small>
    </CJumbotron>
    
    <div class="subbanners">
      <div class="subbanner-item">
        <CImg
          shape="rounded"
          src="https://cdn.s777.club/web/subbanner1.png"
          :fluidGrow='true'
        />
        <div class="subbanner-text">
          <h4>Welcome Bonus</h4>
          <p>Up to $250 welcome bonus</p>
        </div>
      </div>
      <div class="subbanner-item">
        <CImg
          shape="rounded"
          src="https://cdn.s777.club/web/subbanner2.png"
          :fluidGrow='true'
        />
        <div class="subbanner-text">
          <h4>Highroller Bonus</h4>
          <p>10% additional bonus</p>
        </div>
      </div>
      <div class="subbanner-item">
        <CImg
          shape="rounded"
          src="https://cdn.s777.club/web/subbanner3.png"
          :fluidGrow='true'
        />
        <div class="subbanner-text">
          <h4>Weekly Cashbacks</h4>
          <p>Up to 10% cashbacks</p>
        </div>
      </div>
    </div>

    <!--<h3 class="game-header">Halloween Games</h3>

    <CRow :gutters='false'>
      <CCol col='6' sm='3' md='3' lg='2' v-for='(game, index) in halloweenGames' :key='game._id' class='game-item' >
        
        <div v-if='!game.isImageLoaded'>
          <div class='sq'>
            <div class='sqcontent'>
              <vue-skeleton-loader rounded type='rect' height='100%' width='100%' radius='4' color='#251146' animation='fade'/>
            </div>
          </div>
          <div style='margin-top:10px'>
            <vue-skeleton-loader rounded type='rect' height='24px' width='100%' radius='10' color='#251146' animation='fade'/>
          </div>
          <div style='margin-top: 10px;margin-bottom:15px'>
            <vue-skeleton-loader rounded type='rect' height='11px' width='50%' radius='30' color='#251146' animation='fade'/>
          </div>
        </div>

        <div v-show='game.isImageLoaded' @click='launchGame(game)'>
          <img v-if="game.provider == 'PS'" :src="'https://cdn.s777.club/web/playstar/' + game.providerID + '.jpeg'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded' />
          <img v-if="game.provider == 'JK'" :src="'https://cdn.s777.club/web/joker/' + game.providerID + '.png'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'FK'" :src="'https://cdn.s777.club/web/funky/' + game.providerID + '.jpg'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'BG'" :src="'https://cdn.s777.club/web/bgaming/' + game.providerID + '.png'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'OP'" :src="'https://cdn.s777.club/web/onlyplay/' + game.providerID.replace(':', '') + '.png'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'KG'" :src="'https://cdn.s777.club/web/kaga/' + game.providerID.replace(':', '') + '.png'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'VP'" :src="'https://cdn.s777.club/web/vpower/' + game.providerID + '.jpg'" @load='imageOnLoad1(index)' class='game-banner img-fluid w-100 rounded'/>

          <div class='game-overlay'>
            <CLink><img src='https://cdn.s777.club/web/play-icon.png'/></CLink>
            <CLink>
              <span v-if='isLoggedIn'>Play</span>
              <span v-if='!isLoggedIn'>Demo</span>
            </CLink>
          </div>
          <div class='game-name'>{{game.name}}</div>
          <div class='game-provider' v-if="game.provider == 'PS'">Playstar</div>
          <div class='game-provider' v-if="game.provider == 'JK'">Joker</div>
          <div class='game-provider' v-if="game.provider == 'FK'">Funky</div>
          <div class='game-provider' v-if="game.provider == 'BG'">Bgaming</div>
          <div class='game-provider' v-if="game.provider == 'OP'">Onlyplay</div>
          <div class='game-provider' v-if="game.provider == 'KG'">Kaga</div>
          <div class='game-provider' v-if="game.provider == 'VP'">Vpower</div>
        </div>
      </CCol> 
    </CRow>-->

    <h3 class="game-header">Latest Games</h3>

    <CRow :gutters='false' style="position:relative;z-index:1;">
      <CCol col='6' sm='3' md='3' lg='2' v-for='(game, index) in latestGames' :key='game._id' class='game-item' >
        
        <div v-if='!game.isImageLoaded'>
          <div class='sq'>
            <div class='sqcontent'>
              <vue-skeleton-loader rounded type='rect' height='100%' width='100%' radius='4' color='#251146' animation='fade'/>
            </div>
          </div>
          <div style='margin-top:10px'>
            <vue-skeleton-loader rounded type='rect' height='24px' width='100%' radius='10' color='#251146' animation='fade'/>
          </div>
          <div style='margin-top: 10px;margin-bottom:15px'>
            <vue-skeleton-loader rounded type='rect' height='11px' width='50%' radius='30' color='#251146' animation='fade'/>
          </div>
        </div>

        <div v-show='game.isImageLoaded' @click='launchGame(game)'>
          <img v-if="game.provider == 'PS'" :src="'https://cdn.s777.club/web/playstar/' + game.providerID + '.jpeg'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded' />
          <img v-if="game.provider == 'JK'" :src="'https://cdn.s777.club/web/joker/' + game.providerID + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'FK'" :src="'https://cdn.s777.club/web/funky/' + game.providerID + '.jpg'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'BG'" :src="'https://cdn.s777.club/web/bgaming/' + game.providerID + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'OP'" :src="'https://cdn.s777.club/web/onlyplay/' + game.providerID.replace(':', '') + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'KG'" :src="'https://cdn.s777.club/web/kaga/' + game.providerID.replace(':', '') + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'VP'" :src="'https://cdn.s777.club/web/vpower/' + game.providerID + '.jpg'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>

          <div class='game-overlay'>
            <CLink><img src='https://cdn.s777.club/web/play-icon.png'/></CLink>
            <CLink>
              <span v-if='isLoggedIn'>Play</span>
              <span v-if='!isLoggedIn'>Demo</span>
            </CLink>
          </div>
          <div class='game-name'>{{game.name}}</div>
          <div class='game-provider' v-if="game.provider == 'PS'">Playstar</div>
          <div class='game-provider' v-if="game.provider == 'JK'">Joker</div>
          <div class='game-provider' v-if="game.provider == 'FK'">Funky</div>
          <div class='game-provider' v-if="game.provider == 'BG'">Bgaming</div>
          <div class='game-provider' v-if="game.provider == 'OP'">Onlyplay</div>
          <div class='game-provider' v-if="game.provider == 'KG'">Kaga</div>
          <div class='game-provider' v-if="game.provider == 'VP'">Vpower</div>
        </div>
      </CCol> 
    </CRow>

    <div style='width:100%;text-align: center;'>
      <CButton shape="pill" size="lg" class="show-all"  to="/games/all">Show All</CButton>
    </div>

    <!--<div class="home-after-shoutout">
      <div style="display:inline-block;">
        <div class="fivestar1">Rated 5-Star Excellent</div>
        <div class="fivestar2">Customer Trust Ratings</div>
      </div>
      <img src="https://cdn.s777.club/web/fivestar.png" class="fivestarimg"/>
    </div>-->

  </div>

</template>

<script>
import apiGames from '@/api/games'
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'Home',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  components: {
  },
  data () {
    return {
      isLoading: false,
      latestGames: [],
      halloweenGames: []
    }
  },
  created() {
    this.fetchLatestGames()
    //this.fetchHalloweenGames()
    
    if(this.$route.query.t){
      
      var eventID = 'home-verification-' + Date.now()
      EventBus.$emit('loadingTrue', eventID)

      //this.openLoginModal()
      
      if(this.$store.state.isLoggedIn){
        this.$toast.error("Account verification failed. You are already logged in.", {
          position: "top-center",
          timeout: 5000,
          hideProgressBar: true
        })
        EventBus.$emit('loadingFalse', eventID)
      }else{
        apiAccount.verifySignup(this.$route.query.t).then(response => {
          this.$toast.success("Your account has been successfully verified. You may proceed to login to your game account.", {
            position: "top-center",
            timeout: 5000,
            hideProgressBar: true
          })
          var query = Object.assign({}, this.$route.query)
          delete query.t
          this.$router.replace({ query })
          this.openLoginModal()
          EventBus.$emit('loadingFalse', eventID)
        }).catch((err) => {
          this.$toast.error("Account verification failed. Please contact our support staff for assistance.", {
            position: "top-center",
            timeout: 5000,
            hideProgressBar: true
          })
          var query = Object.assign({}, this.$route.query)
          delete query.t
          this.$router.replace({ query })
          EventBus.$emit('loadingFalse', eventID)
        })
      }

    }

    if(this.$route.query.u){
      
      var eventID = 'home-verification-' + Date.now()
      EventBus.$emit('loadingTrue', eventID)

      apiAccount.verifyUpdateProfile(this.$route.query.u).then(response => {
        this.$toast.success("Your profile has been successfully updated and verified.", {
          position: "top-center",
          timeout: 5000,
          hideProgressBar: true
        })
        var query = Object.assign({}, this.$route.query)
        delete query.u
        this.$router.replace({ query })
        EventBus.$emit('loadingFalse', eventID)
      }).catch((err) => {
        this.$toast.error("Update of profile failed. Please contact our support staff for assistance.", {
          position: "top-center",
          timeout: 5000,
          hideProgressBar: true
        })
        var query = Object.assign({}, this.$route.query)
        delete query.u
        this.$router.replace({ query })
        EventBus.$emit('loadingFalse', eventID)
      })

    }

    if(this.$route.query.p){
    
      EventBus.$emit('openNewPasswordModal', this.$route.query.p)
      var query = Object.assign({}, this.$route.query)
      delete query.p
      this.$router.replace({ query })

    }

  },
  methods: {
    openSignupModal() {
      EventBus.$emit('openSignupModal')
    },
    openLoginModal() {
      EventBus.$emit('openLoginModal')
    },
    openDepositModal(){
      EventBus.$emit('openDepositModal')
    },
    fetchLatestGames() {

      for (let i = 0; i < 12; i++) {
        this.latestGames.splice(i, 1, {})
      }

      //this.isLoading = true;
      //var eventID = 'home-latestgames-' + Date.now()
      //EventBus.$emit('loadingTrue', eventID)
      apiGames.getLatest().then(response => {
        //this.latestGames = response.data.data
        var games = response.data.data
        for (let i = 0; i < 12; i++) {
          if(games[i]){
            games[i].isImageLoaded = false
            this.latestGames.splice(i, 1, games[i])
          }else{
            break
          }
        }

        //this.isLoading = false;
        //EventBus.$emit('loadingFalse', eventID)
      }).catch(() => {
        
      })
    },
    fetchHalloweenGames() {

      for (let i = 0; i < 12; i++) {
        this.halloweenGames.splice(i, 1, {})
      }

      //this.isLoading = true;
      //var eventID = 'home-latestgames-' + Date.now()
      //EventBus.$emit('loadingTrue', eventID)
      apiGames.getHalloween().then(response => {
        //this.latestGames = response.data.data
        var games = response.data.data
        for (let i = 0; i < 12; i++) {
          if(games[i]){
            games[i].isImageLoaded = false
            this.halloweenGames.splice(i, 1, games[i])
          }else{
            break
          }
        }

        //this.isLoading = false;
        //EventBus.$emit('loadingFalse', eventID)
      }).catch(() => {
        
      })
    },
    imageOnLoad(index){
      this.latestGames[index].isImageLoaded = true
    },
    imageOnLoad1(index){
      this.halloweenGames[index].isImageLoaded = true
    },
    launchGame(game){
      EventBus.$emit('launchGame', game)
    }
  }
}
</script>

