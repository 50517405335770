import request from '@/api/axios-request'

const apiAccount = {
  
  signup(email, username, password) {
    return request({
      url: '/pl/account/signup',
      method: 'post',
      data: {
        email,
        username,
        password,
        siteCode: 'S7'
      }
    })
  },

  resendSignupEmail(email) {
    return request({
      url: '/pl/account/resendSignupEmail',
      method: 'post',
      data: {
        email,
        siteCode: 'S7'
      }
    })
  },

  verifySignup(token) {
    return request({
      url: '/pl/account/verifySignup',
      method: 'post',
      data: {
        token
      }
    })
  },

  profile() {
    return request({
      url: '/pl/account/profile',
      method: 'get'
    })
  },

  updateProfile(fullname, mobile, email, password) {
    return request({
      url: '/pl/account/updateProfile',
      method: 'post',
      data: {
        fullname,
        mobile,
        email,
        password
      }
    })
  },

  resendUpdateProfileEmail(email) {
    return request({
      url: '/pl/account/resendUpdateProfileEmail',
      method: 'post',
      data: {
        email
      }
    })
  },

  verifyUpdateProfile(token) {
    return request({
      url: '/pl/account/verifyUpdateProfile',
      method: 'post',
      data: {
        token
      }
    })
  },

  login(username, password) {
    return request({
      url: '/pl/account/login',
      method: 'post',
      data: {
        username,
        password,
        siteCode: 'S7'
      }
    })
  },

  logout() {
    return request({
      url: '/pl/account/logout',
      method: 'post'
    })
  },

  forgetPassword(email) {
    return request({
      url: '/pl/account/forgetPassword',
      method: 'post',
      data: {
        email,
        siteCode: 'S7'
      }
    })
  },

  newPassword(password1, password2, token) {
    return request({
      url: '/pl/account/newPassword',
      method: 'post',
      data: {
        password1,
        password2,
        token
      }
    })
  },

  playstarDeposit() {
    return request({
      url: '/pl/account/playstarDeposit',
      method: 'post'
    })
  },

  supportTicket(fullname, mobile, email, message){
    return request({
      url: '/pl/account/supportTicket',
      method: 'post',
      data: {
        fullname,
        mobile,
        email,
        message,
        siteCode: 'S7'
      }
    })
  }

}

export default apiAccount